import {
  DescribeInstancesCommand,
  StartInstancesCommand,
  StopInstancesCommand,
} from "@aws-sdk/client-ec2";

export function describeInstances(ec2Client, InstanceIds) {
  const command = new DescribeInstancesCommand({ InstanceIds });
  return ec2Client.send(command);
}

export function startInstances(ec2Client, InstanceIds) {
  const command = new StartInstancesCommand({ InstanceIds });
  return ec2Client.send(command);
}

export function stopInstances(ec2Client, InstanceIds) {
  const command = new StopInstancesCommand({ InstanceIds });
  return ec2Client.send(command);
}
