import { GetCommandInvocationCommand, SendCommandCommand } from "@aws-sdk/client-ssm";
import { InvocationDoesNotExist } from '@aws-sdk/client-ssm';
import { DEFAULT_POLL_TIMEOUT } from "../constants";
import { sleep } from '../utils';

export function getCommandInvocation(ssmClient, CommandId, InstanceId) {
  const command = new GetCommandInvocationCommand({ CommandId, InstanceId });
  return ssmClient.send(command);
}

export function sendCommand(ssmClient, DocumentName, InstanceIds, Parameters) {
  const command = new SendCommandCommand({ DocumentName, InstanceIds, Parameters });
  return ssmClient.send(command);
}

export async function sendAndAwaitCommand(ssmClient, DocumentName, InstanceId, Parameters) {
  const sendCommandResponse = await sendCommand(
    ssmClient,
    DocumentName,
    [InstanceId],
    Parameters,
  );

  const { CommandId } = sendCommandResponse.Command;

  while (true) {
    try {
      await sleep(DEFAULT_POLL_TIMEOUT);
      const commandInvocationResponse = await getCommandInvocation(ssmClient, CommandId, InstanceId);
      const { ResponseCode } = commandInvocationResponse;
      if (ResponseCode < 0) {
        continue;
      }
      return commandInvocationResponse;
    } catch (e) {
      if (e instanceof InvocationDoesNotExist) {
        continue;
      }
      throw e;
    }
  }
}
