export const AWS_REGION = 'us-east-1';
export const IDENTITY_POOL_ID = 'us-east-1:d99e23a3-e56e-4922-be32-23fe5f72a4d1';
export const USER_POOL_ID = 'us-east-1_4CSgprRif';
export const USER_POOL_WEB_CLIENT_ID = '4rdl8u1kddv7clupqtt54i98e';

export const DEFAULT_POLL_TIMEOUT = 2000;

export const MC_LIST_UUIDS = 'list uuids';
export const MC_SEED = 'seed';

// https://docs.aws.amazon.com/AWSEC2/latest/APIReference/API_InstanceState.html
export const InstanceStateCodes = {
  PENDING: 0,
  RUNNING: 16,
  SHUTTING_DOWN: 32,
  TERMINATED: 48,
  STOPPING: 64,
  STOPPED: 80,
};

// https://refspecs.linuxfoundation.org/LSB_5.0.0/LSB-Core-generic/LSB-Core-generic.html#INISCRPTACT
export const ServiceStatusCodes = {
  RUNNING: 0,
  DEAD_A: 1,
  DEAD_B: 2,
  NOT_RUNNING: 3,
  UNKNOWN: 4,
};

export const EC2_INSTANCE_ID_RIOT_ZONE = 'i-062d5c4574466a01e';

export const LAMBDA_EXECUTE_MINECRAFT_COMMANDS = 'executeMinecraftCommands';

export const SSM_AWS_RUN_SCHELL_SCRIPT = 'AWS-RunShellScript';
